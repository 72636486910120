import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

const managerDetails = {
  "Rafael Barthaburu": { password: "pass123", role: "manager" },
  "Gonzalo Boullosa": { password: "pass456", role: "manager" },
  "Roberto Genta": { password: "pass789", role: "manager" },
  "Patricia Gomez": { password: "pass101", role: "manager" },
  "Guzman Johnson": { password: "pass102", role: "manager" },
  "Jazmine Kleiman": { password: "pass103", role: "manager" },
  "Rodrigo Melian": { password: "pass104", role: "manager" },
  "Ximena Molina": { password: "pass105", role: "manager" },
  "Silvina More": { password: "pass007", role: "manager" },
  "Valentina More": { password: "pass106", role: "admin" },
  "Santiago Mut": { password: "pass107", role: "manager" },
  "Leticia Ortiz": { password: "pass108", role: "admin" },
  "Alvaro Palombo": { password: "pass109", role: "manager" },
  "Michel Rodriguez": { password: "pass110", role: "manager" }
};

const LoginManager = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    sessionStorage.removeItem('userId');
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    const user = managerDetails[username];
    if (user && user.password === password) {
      const token = btoa(`${username}:${user.role}`); 
      sessionStorage.setItem('token', token);
      onLogin(username, user.role);
    } else {
      Swal.fire("Error", "Invalid credentials", "error");
    }
  };

  return (
    <div>
      <h2>Login Manager</h2>
      <form onSubmit={handleLogin}>
        <div className='flexcontent'>
          <div className='formcontent'>
            <label>Usuario:
              <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
            </label>
          </div>
          <div className='formcontent'>
            <label>Password:
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
          </div>
        </div>   
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default LoginManager;