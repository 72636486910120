import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Formulario from './Formulario';
import LoginManager from './LoginManager';

const API_URL = process.env.REACT_APP_API_URL;

const ManagerPage = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [authenticatedManager, setAuthenticatedManager] = useState(null);
  const [managerName, setManagerName] = useState(null);

  useEffect(() => {
    if (authenticatedManager) {
      const token = sessionStorage.getItem('token');
      axios.get(`${API_URL}/api/employees`, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => {
          setEmployees(res.data);
        })
        .catch(err => console.error(err));
    }
  }, [authenticatedManager]);

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
  };

  const handleLogin = (manager, role) => {
    setAuthenticatedManager(manager);
    setManagerName(manager);
    sessionStorage.setItem('role', role); 
  };

  return (
    <div id="lista-empleados">
      {!authenticatedManager ? (
        <LoginManager onLogin={handleLogin} />
      ) : (
        <>
          {selectedEmployee ? (
            <Formulario employee={selectedEmployee} employeenombre={selectedEmployee.nombre} />
          ) : (
            <div>
              <h1>My Equipo VML {managerName}</h1>
              <ul>
                {employees.map(employee => (
                  <li key={employee._id} onClick={() => handleSelectEmployee(employee)}>
                    {employee.nombre}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ManagerPage;
