import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Swal from 'sweetalert2';
import Loader from './Loader'; 


const API_URL = process.env.REACT_APP_API_URL;

const preguntasParte1 = [
  { pregunta: 'Fortalezas y logros del 2023', contenido: '<h3><span>En el recuadro de abajo, completá esta afirmación:</span></br> <strong>Las habilidades que hacen la diferencia en mi trabajo en VML son…</strong></h3> </br> <p>Escribí de 3-5 habilidades que hayas demostrado y el impacto de cada una.</p>' },
  { pregunta: 'Áreas de oportunidades en 2024', contenido: '<h3><span>En el recuadro de abajo, completá esta afirmación:</span></br> <strong>Para dar un valor agregado a mi trabajo en VML, yo debería…</strong></h3> </br> <p>Escribí de 1-3 habilidades que deberías desarrollar.</p>' }
];

const preguntasParte2 = [
  { pregunta: 'Fortalezas & contribuciones del 2023', contenido: '<h3>En el recuadro de abajo:</br> <strong>Completá con las habilidades que contribuyeron con mayor valor a su trabajo en VML</strong></h3> </br> <p>Escribí de 3-5 habilidades demostradas y el impacto de cada una de estas habilidades.</p>' },
  { pregunta: 'Potencial de crecimiento en 2024', contenido: '<h3><span>En el recuadro de abajo, completá esta afirmación:</span></br> <strong>Para proporcionar un valor agregado a tu trabajo en VML como integrante de mi equipo podrías enfocarte en el desarrollo de...</strong></h3></br><p>Escribí de 1-3 habilidades a desarrollar.</p>' }
];

const preguntasParte3 = [
  { pregunta: 'Propósito | Inspiración', contenido: '<h3><span>En la caja de texto, completá la siguiente afirmación:</span></br> Estoy inspirado/inspirada para...</h3> </br> <p>Inserta el impacto que deseas hacer a través de tu trabajo y carrera.<p>'},
  { pregunta: 'Propósito | Ambición', contenido: '<h3>A continuación, tomá nota de las experiencias laborales que deseas tener, los movimientos de carrera que quieres hacer y los logros que quieres alcanzar en el próximo año.</h3>'},
  { pregunta: 'Prioridades | Trabajo', contenido: '<h3>En la caja de texto complete la siguiente afirmación:</br> Para dar un valor agregado a VML este año, yo voy a...</h3> </br> <p>Escribí de 3-5 metas laborales más importantes para este año.</p>'},
  { pregunta: 'Prioridades | Desarrollo de Carrera', contenido: '<h3>En la caja de texto, completá la afirmación:</br> Para fortalecer mi marca personal y hacer crecer mi carrera este año, voy a...</h3> </br> <p>Describe de 2-3 metas importantes para este año.</p>'},
  { pregunta: 'Prioridades | Bienestar', contenido: '<h3>Para cuidar mi bienestar este año, voy a...</h3></br><p>Escribí de 1-2 metas de autocuidado y pertenencia más importantes para este año.</p>'}
];

const Formulario = ({ employee, employeenombre }) => {
  const [step, setStep] = useState(employee ? 0 : -1); 
  const [responses, setResponses] = useState({
    parte1: Array(preguntasParte1.length).fill(''),
    parte2: Array(preguntasParte2.length).fill(''),
    parte3: Array(preguntasParte3.length).fill('')
  });

  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nombre, setNombre] = useState('');
  const [manager, setManager] = useState('');
  const [userId, setUserId] = useState(sessionStorage.getItem('userId') || (employee ? employee._id : null));
  const [userName, setUserName] = useState('');
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);  
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  console.log(isLoggedIn)
  






  useEffect(() => {
    if (userId) {
      loadResponses(userId);
    }
  }, [userId]);

  const loadResponses = (userId) => {
    console.log(userId)
    setLoading(true);
    axios.get(`${API_URL}/api/respuestas/${userId}`)
      .then(res => {
        const { parte1, parte2, parte3, user } = res.data;
        console.log("parte1:", parte1);
        console.log("parte2:", parte2);
        console.log("parte3:", parte3);
        console.log("user:", user);
        setResponses({
          parte1: parte1 ? parte1.map(p => p.respuesta) : Array(preguntasParte1.length).fill(''),
          parte2: parte2 ? parte2.map(p => p.respuesta) : Array(preguntasParte2.length).fill(''),
          parte3: parte3 ? parte3.map(p => p.respuesta) : Array(preguntasParte3.length).fill('')
        });
        if (user && user.nombre) {
          setUserName(user.nombre);
        }
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  };

  




  const validateLogin = () => {
    let valid = true;
    let errors = { email: '', password: '' };

    if (!email) {
      errors.email = 'El correo electrónico es obligatorio.';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'El correo electrónico no es válido.';
      valid = false;
    }

    if (!password) {
      errors.password = 'La contraseña es obligatoria.';
      valid = false;
    }

    setValidationErrors(errors);
    return valid;
  };




  const handleNext = async () => {
    if (step === 'register' || step === 'login') {
      setStep(0);
      return;
    }

    // Lógica para Parte 1
    if (step < preguntasParte1.length) {
      if (step === preguntasParte1.length - 1) {
        if (responses.parte1.every(res => res !== '')) {
          await saveParte1();
        } else {
          Swal.fire('Error', 'Por favor, completa todas las preguntas del "Paso 1: Autoreflexión" antes de continuar.', 'error');
          return;
        }
      }
      setStep(step + 1);
      return;
    }

    // Lógica para Parte 2
    if (step < preguntasParte1.length + preguntasParte2.length) {
      if (step === preguntasParte1.length + preguntasParte2.length - 1) {
        if (responses.parte2.every(res => res !== '')) {
          await saveParte2();
        } else {
          Swal.fire('Error', 'Por favor, completa todas las preguntas del "Paso 2: Manager" antes de continuar.', 'error');
          return;
        }
      }
      setStep(step + 1);
      return;
    }

    // Lógica para Parte 3
    if (step < preguntasParte1.length + preguntasParte2.length + preguntasParte3.length) {
      if (step === preguntasParte1.length + preguntasParte2.length + preguntasParte3.length - 1) {
        if (responses.parte3.every(res => res !== '')) {
          await saveParte3();
          setIsFormCompleted(true); 

        } else {
          Swal.fire('Error', 'Por favor, completa todas las preguntas del "Paso 3: Conversación de Thrive" antes de continuar.', 'error');
          return;
        }
      }
      setStep(step + 1);
      return;
    }


    // If the step count exceeds the total number of steps, reset to the beginning or handle accordingly
    if (step >= preguntasParte1.length + preguntasParte2.length + preguntasParte3.length) {
      Swal.fire('Formulario Completado', 'Has completado todas las partes del formulario.', 'success');
      setStep(0); // Or handle the end of the form as needed
    }


  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    } else if (step === 0) {
      setStep(-1); // Volver a la pantalla inicial
    }
  };

  const handleRegister = () => {
    setLoading(true);
    axios.post(`${API_URL}/api/register`, { email, password, nombre, manager })
      .then(res => {
        Swal.fire('Usuario registrado', `Bienvenido, ${nombre}`, 'success');
        setUserName(nombre);
        setUserId(res.data._id);
        sessionStorage.setItem('userId', res.data._id);
        setStep(0);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  };

  const handleLogin = () => {
    if (validateLogin()) {
      setLoading(true);
      axios.post(`${API_URL}/api/login`, { email, password })
        .then(res => {
          const { nombre } = res.data.user; // Asegúrate de que la respuesta del servidor incluya el nombre del usuario
          Swal.fire('Inicio de sesión exitoso', `Bienvenido, ${nombre}`, 'success');
          setUserName(nombre);
          setUserId(res.data.user._id);
          sessionStorage.setItem('userId', res.data.user._id);
          setIsLoggedIn(true);
          setStep(0);
        })
        .catch(err => {
          if (err.response && err.response.status === 400) {
            Swal.fire('Error', err.response.data, 'error');
          } else {
            console.error(err);
          }
        })
        .finally(() => setLoading(false));
    } else {
      Swal.fire('Error', 'Por favor, corrige los errores antes de continuar.', 'error');
    }
  };

  const handleChange = (e) => {
    const newResponses = { ...responses };
    const value = e.target.value;
    if (step < preguntasParte1.length) {
      newResponses.parte1[step] = value;
    } else if (step < preguntasParte1.length + preguntasParte2.length) {
      newResponses.parte2[step - preguntasParte1.length] = value;
    } else {
      newResponses.parte3[step - preguntasParte1.length - preguntasParte2.length] = value;
    }
    setResponses(newResponses);
  };

  const saveParte1 = async () => {
    setLoading(true);
    try {
      const data = {
        userId,
        parte1: responses.parte1.map((respuesta, index) => ({
          pregunta: preguntasParte1[index].pregunta,
          respuesta: respuesta || '',
        })),
      };
      console.log('Saving Parte 1:', data);
      await axios.post(`${API_URL}/api/respuestas/parte1`, data);
      Swal.fire(
        `Felicitaciones ${userName}${employeenombre}`,
        'terminaste tu formulario de autoreflexión. Ahora tu Manager completará el formulario <strong>Paso 2: Manager</strong>, luego agendará una reunión para completar el <strong>Paso 3: Conversación de Thrive</strong> en conjunto.',
        'success'
      );
      } catch (err) {
      console.error(err);
      } finally {
        setLoading(false);
      }
  };
  

  const saveParte2 = async () => {
    setLoading(true);
    try {
      const data = {
        userId,
        parte2: responses.parte2.map((respuesta, index) => ({
          pregunta: preguntasParte2[index].pregunta,
          respuesta: respuesta || '',
        })),
      };
      console.log('Saving Parte 2:', data);
      await axios.post(`${API_URL}/api/respuestas/parte2`, data);
      Swal.fire(
       'Felicitaciones Manager',
       `Completaste el <strong>Paso 2</strong>, a continuación agendá una reunión para completar el <strong>Paso 3: Conversación de Thrive</strong> en conjunto con <strong>${userName}${employeenombre}</strong>.`, 'success');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const saveParte3 = async () => {
    setLoading(true);
    try {
      const data = {
        userId,
        parte3: responses.parte3.map((respuesta, index) => ({
          pregunta: preguntasParte3[index].pregunta,
          respuesta: respuesta || '',
        })),
      };
      console.log('Saving Parte 3:', data);
      await axios.post(`${API_URL}/api/respuestas/parte3`, data);
      Swal.fire(`Felicitaciones <strong>${userName}${employeenombre}</strong>, Terminaste la conversación de <strong>VML THRIVE</strong>`, '', 'success');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const renderPregunta = () => {
    if (step < preguntasParte1.length) {
      return (
        <div>
          <p className='usernameform'>VML Thrive de {userName} {employeenombre}</p>
          <h1 className='titulo_paso'>Paso 1: Autoreflexión</h1>
          <h1>{preguntasParte1[step].pregunta}</h1>
          <div dangerouslySetInnerHTML={{ __html: preguntasParte1[step].contenido }}></div>
          <textarea
            value={responses.parte1[step] || ''}
            onChange={handleChange}
            placeholder="Escribe tu respuesta aquí..."
          />
        </div>
      );
    } else if (step < preguntasParte1.length + preguntasParte2.length) {
      const index = step - preguntasParte1.length;
      return (
        <div>
          <p className='usernameform'>VML Thrive de {userName} {employeenombre}</p>
          <h1 className='titulo_paso'>Paso 2: Manager</h1>
          <h1>{preguntasParte2[index].pregunta}</h1>
          <div dangerouslySetInnerHTML={{ __html: preguntasParte2[index].contenido }}></div>
          <textarea
            className={isLoggedIn ? 'hidden-textarea' : ''}
            value={responses.parte2[index] || ''}
            onChange={handleChange}
            placeholder="Escribe tu respuesta aquí..."
          />
        </div>
      );
    } else if (step < preguntasParte1.length + preguntasParte2.length + preguntasParte3.length) {
      const index = step - preguntasParte1.length - preguntasParte2.length;
      return (
        <div>
          <p className='usernameform'>VML Thrive de {userName} {employeenombre}</p>
          <h1 className='titulo_paso'>Paso 3: Conversación de Thrive</h1>
          <h1>{preguntasParte3[index].pregunta}</h1>
          <div dangerouslySetInnerHTML={{ __html: preguntasParte3[index].contenido }}></div>
          <textarea
            value={responses.parte3[index] || ''}
            onChange={handleChange}
            placeholder="Escribe tu respuesta aquí..."
          />
        </div>
      );
    } else if (isFormCompleted) {
      return(
        <h1>Formulario completado.</h1>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {loading && <Loader />} {/* Muestra el loader si loading es true */}
      <SwitchTransition>
        <CSSTransition
          key={step}
          timeout={300}
          classNames="fade"
        >
          <div className='conenedorpregunta'>
            {step === -1 && !employee ? (
              <div>
                <h1>Bienvenido/a</h1>
                <h3 className='marg-b'>THRIVE es nuestro proceso global para acompañar el crecimiento profesional
                mediante el fortalecimiento de tu marca personal.</h3>
                <h3>Utilizá esta guía THRIVE
                para enfocarte en tu desarrollo profesional en VML completando los
                siguientes pasos.</h3>

                <button onClick={() => setStep('login')}>Iniciar sesión</button>
                <button onClick={() => setStep('register')}>Registrarse</button>
              </div>
            ) : step === 'register' ? (
              <div>
                <h1>Registro</h1>
                <div className='flexcontent'>
                  <div className='formcontent'>
                    Correo electrónico:
                    <label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Escribe tu correo"
                      />
                    </label>
                  </div>
                  <div className='formcontent'>
                    Contraseña:
                    <label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Escribe tu contraseña"
                      />
                    </label>
                  </div>
                </div>

                <div className='flexcontent'>
                  <div className='formcontent'>
                    Tu nombre completo:
                    <label>
                      <input
                        type="text"
                        value={nombre}
                        onChange={(e) => setNombre(e.target.value)}
                        placeholder="Escribe tu nombre"
                      />
                    </label>
                  </div>

                  <div className='formcontent'>
                    Tu Manager:
                    <label className='select'>
                      <select
                        value={manager}
                        onChange={(e) => setManager(e.target.value)}
                      >
                        <option value="">Seleccione un manager</option>
                        <option value="Rafael Barthaburu">Rafael Barthaburu</option>
                        <option value="Gonzalo Boullosa">Gonzalo Boullosa</option>
                        <option value="Roberto Genta">Roberto Genta</option>
                        <option value="Patricia Gomez">Patricia Gomez</option>
                        <option value="Guzman Johnson">Guzman Johnson</option>
                        <option value="Jazmine Kleiman">Jazmine Kleiman</option>
                        <option value="Rodrigo Melian">Rodrigo Melian</option>
                        <option value="Ximena Molina">Ximena Molina</option>
                        <option value="Silvina More">Silvina More</option>
                        <option value="Valentina More">Valentina More</option>
                        <option value="Santiago Mut">Santiago Mut</option>
                        <option value="Leticia Ortiz">Leticia Ortiz</option>
                        <option value="Alvaro Palombo">Alvaro Palombo</option>
                        <option value="Michel Rodriguez">Michel Rodriguez</option>
                      </select>
                    </label>
                  </div>
                </div>   

                <div className='flexbuttons'>
                  <button onClick={handleRegister}>Registrarse</button>
                  <button onClick={() => setStep(-1)}>Atrás</button>
                </div>
                
              </div>
            ) : step === 'login' ? (
              <div>
                <h1>Iniciar sesión</h1>
                <div className='flexcontentlog'>
                  <div className='formcontent'>
                    Correo electrónico:
                    <label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Escribe tu correo"
                      />
                    </label>
                    {validationErrors.email && <p className="error">{validationErrors.email}</p>}
                  </div>
                  <div className='formcontent'>
                    Contraseña:
                    <label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Escribe tu contraseña"
                      />
                    </label>
                    {validationErrors.password && <p className="error">{validationErrors.password}</p>}
                  </div>
                  <div className='formcontent'>
                    <button onClick={handleLogin}>Iniciar sesión</button>
                    <button onClick={() => setStep(-1)}>Atrás</button>
                  </div>  
                </div> 
              </div>
            ) : (
              renderPregunta()
            )}
            <div className='botonera_back_next'>
              {isFormCompleted ? (
                <button onClick={() => window.location.href = '/'}>Salir</button>
              ) : (
                <>
                  {step > 0 && step !== 'login' && step !== 'register' && (
                    <button onClick={handleBack}>Atrás</button>
                  )}
                  {(step === -1 || step === 'login' || step === 'register') ? null : (
                    <button onClick={handleNext}>{step === preguntasParte1.length + preguntasParte2.length + preguntasParte3.length - 1 ? 'Enviar' : 'Siguiente'}</button>
                  )}
                </>
              )}
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Formulario;
