import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Formulario from './componentes/Formulario.js';
import Header from './componentes/Header.js';
import ManagerPage from './componentes/ManagerPage.js';  // Asegúrate de importar tu componente ManagerPage

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <header className="App-header">
          <Routes>
            <Route path="/" element={<Formulario />} />
            <Route path="/manager" element={<ManagerPage />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
