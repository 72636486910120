import React from 'react';
import logo from '../assets/logo.png'; 

const Header = () => {
  const handleLogoClick = () => {
    window.location.reload();
  };

  return (
    <header className="header">
      <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
    </header>
  );
};

export default Header;
